/* Colors */
@font-face {
  font-family: Campton;
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-light-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-light-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: bold;
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-bold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-bold-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: 600;
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff") format("woff");
}
.altai-theme-content #bodyView .comeos-highlighted {
  padding-top: 50px;
  padding-bottom: 50px;
  font-family: 'Campton';
}
.altai-theme-content #bodyView .comeos-highlighted .event-date {
  color: black;
  margin-bottom: 20px;
}
.altai-theme-content #bodyView .comeos-highlighted .card-1 .card__body h5 {
  font-weight: 800;
}
.altai-theme-content #bodyView .comeos-highlighted a .card p {
  color: black;
}
.altai-theme-content #bodyView .comeos-highlighted h1,
.altai-theme-content #bodyView .comeos-highlighted h3 {
  font-family: 'Campton';
}
.altai-theme-content #bodyView .comeos-highlighted h1 {
  margin: 0;
  margin-top: 20px;
}
.altai-theme-content #bodyView .comeos-highlighted h3 {
  font-size: 18px;
}
.altai-theme-content #bodyView .comeos-highlighted .btn.rounded-button {
  border-radius: 100px !important;
  background-color: transparent;
  border-color: black;
  margin-left: auto;
  margin-right: auto;
  color: black;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-highlighted .button-container {
  display: flex;
  justify-content: center;
}
.altai-theme-content #bodyView .comeos-highlighted-light-grey {
  background-color: #f3f3f3;
}
.altai-theme-content #bodyView .comeos-highlighted-light {
  background-color: #FFFEFF;
}
.altai-theme-content #bodyView .comeos-highlighted-green {
  background-color: #19d1c6;
}
.altai-theme-content #bodyView .comeos-highlighted-green h1,
.altai-theme-content #bodyView .comeos-highlighted-green h3 {
  color: white;
}
.altai-theme-content #bodyView .comeos-highlighted-green .btn.rounded-button {
  border-color: white;
  color: white;
}
.altai-theme-content #bodyView .comeos-highlighted-green .btn.rounded-button:hover {
  background-color: white;
  border-color: white !important;
  color: black !important;
  cursor: pointer;
}
.altai-theme-content #bodyView .comeos-highlighted-dark {
  background-color: #019de4;
  color: white;
}
.altai-theme-content #bodyView .comeos-highlighted-dark h1,
.altai-theme-content #bodyView .comeos-highlighted-dark h3 {
  color: white;
}
.altai-theme-content #bodyView .comeos-text-banner {
  font-family: 'Campton';
  font-size: 1.3em;
  text-align: center;
}
.altai-theme-content #bodyView .comeos-text-banner p,
.altai-theme-content #bodyView .comeos-text-banner span {
  line-height: 1.8em;
}
.altai-theme-content #bodyView .comeos-text-banner-black {
  background-color: black;
  color: white;
}
.altai-theme-content #bodyView .comeos-text-banner-black a {
  color: white;
}
.altai-theme-content #bodyView .comeos-text-banner-white {
  background-color: white;
  color: black;
}
.altai-theme-content #bodyView .comeos-text-banner-white a {
  color: black;
}
.altai-theme-content #bodyView .comeos-text-banner-green {
  background-color: #19d1c6;
  color: white;
}
.altai-theme-content #bodyView .comeos-text-banner-green a {
  color: white;
}
.altai-theme-content #bodyView .comeos-text-banner-yellow {
  background-color: #FFCE00;
  color: white;
}
.altai-theme-content #bodyView .comeos-text-banner-yellow a {
  color: white;
}
.altai-theme-content #bodyView .col-centered {
  float: none;
  margin: 0 auto;
}
